<template>
  <div class="modal fade" id="modal__note">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg--blue">
          <h5 class="modal-title">Ghi Chú</h5>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="onSubmitNote">
            <div class="mb-3">
              <label class="form-label">Ghi chú:</label>
              <textarea v-model="form.doctor_note" class="form-control" cols="30" rows="5"></textarea>
            </div>

            <div class="form-footer text-center">
              <button type="submit" class="btn bg-pri bd-pri text-white"
                      :disabled="loading || ! form.doctor_note">
                <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
                <span>Cập Nhật</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ModalDoctorNoteAdd',
    props: ['loading', 'form'],
    data () {
      return {
      }
    },
    mounted () {
    },
    methods: {
      onSubmitNote () {
        this.$emit('onSubmitNote', this.form)
      }
    }
  }
</script>
