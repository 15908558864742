<template>
  <div
    class="modal fade"
    id="modal_add"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLongTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="exampleModalLongTitle"
          >{{edit_category ? 'Cập nhật' : 'Thêm mới loại bài viết' }}</h5>
          <button
            type="button"
            class="close bg-pri bd-pri text-white mr-1"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label
              for=""
              class="robo-16-500 required"
            >Tên loại</label>
            <input
              type="text"
              class="form-control"
              v-model="form.name"
            >
            <span
              class="robo-12-400 text-danger"
              v-if="!form.name"
            >Vui lòng nhập tên loại</span>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="mb-3 d-flex align-items-center">
                <input
                  type="checkbox"
                  aria-label="Hiển thị"
                  class="mr-2"
                  id="status"
                  v-model="form.status"
                >
                <label
                  for="status"
                  class="mb-0 cursor-pointer"
                >Hiển thị</label>
              </div>
              <div class="mb-3 d-flex align-items-center">
                <input
                  type="checkbox"
                  aria-label="Hiển thị"
                  class="mr-2"
                  id="is_prioritize"
                  v-model="form.is_prioritize"
                >
                <label
                  for="is_prioritize"
                  class="mb-0 cursor-pointer"
                >Ưu tiên</label>
              </div>

            </div>
            <!-- <div class="col-6">
              <label
                for="icon"
                class="mb-0 robo-16-500 cursor-pointer"
              >Icon</label>
              <input
                type="file"
                accept="image/*"
                @change="onSelectedIcon($event)"
                class="mb-2"
                id="icon"
              >
              <img
                v-if="!selected_icon && edit_category && edit_category.icon"
                :src="getImageURL(edit_category.icon)"
                class="img-fluid"
                alt=""
              >
              <div v-if="selected_icon">
                <img
                  :src="getBase64URL(selected_icon)"
                  class="img-fluid"
                  alt=""
                >

              </div>
            </div> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="onSubmit()"
            :disabled="!form.name"
          >Lưu</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'ModalNewCategory',
  components: { },
  props: ['edit_category'],
  computed: {
    className () {
      if (appUtils.isAdmin()) return 'AdminRequest'
      if (appUtils.isDoctor()) return 'AdminRequest'
    }
  },
  data () {
    return {
      form: {
        name: '',
        status: 1,
        is_prioritize: false,
        icon: null
      },
      loading: false,
      search: '',
      appUtils,
      selected_icon: null
    }
  },
  mounted () {
    let self = this

    window.$(document).ready(function () {
      window.$('#modal_add').on('shown.bs.modal', function (e) {
        if (self.edit_category) {
          self.form.name = self.edit_category.name
          self.form.status = self.edit_category.status
          self.form.is_prioritize = self.edit_category.is_prioritize
        } else {
          self.form = {
            name: '',
            status: 1,
            is_prioritize: false
          }
        }
      })
    })
  },
  methods: {
    async onSubmit () {
      let self = this
      if (!self.form.name) return
      let params = {
        name: self.form.name,
        status: self.form.status ? 1 : 0,
        is_prioritize: self.form.is_prioritize ? 1 : 0
      }
      if (self.selected_icon) {
        params.icon = self.selected_icon
      }
      self.loading = true

      if (self.edit_category) {
        await self.$rf.getRequest(self.className).updateNewCategory(self.edit_category.id, params).then((rs) => {
          self.modalAdd(false)
          self.toastAddSuccess(true)
        }).finally(() => {
          self.loading = false
        })
      } else {
        await self.$rf.getRequest(self.className).createNewCategory(params).then((rs) => {
          self.modalAdd(false)
          self.toastAddSuccess(false)
        }).finally(() => {
          self.loading = false
        })
      }
    },
    modalAdd (show) {
      window.$('#modal_add').modal(show ? 'show' : 'hide')
    },
    refresh () {
      this.$emit('refresh')
    },
    toastAddSuccess (is_update) {
      this.refresh()
      this.form = {
        name: '',
        status: 1,
        is_prioritize: false,
        icon: null
      }
      this.selected_icon = null
      document.getElementById('icon').value = null
      this.$toast.open({
        message: is_update ? 'Cập nhật thành công' : 'Thêm mới thành công',
        type: 'success'
      })
    },
    onSelectedIcon (e) {
      this.selected_icon = e.target.files[0]
    },
    getImageURL (path) {
      return appUtils.getImageURL(path)
    },
    getBase64URL (file) {
      return window.URL.createObjectURL(file)
    }
  }
}
</script>

<style lang="css" scoped>
</style>