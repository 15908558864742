<template>
  <div>
    <div class="modal" tabindex="-1" role="dialog" id="modal--newspaper">
      <div class="modal-dialog modal-fullscreen" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Thông tin bài viết</h5>
            <span class="btn--close"></span>
          </div>
          <div class="modal-body">

            <div class="form-group mb-3">
              <label for="" class="robo-18-500 mb-1">Loại bài viết</label>
              <v-select multiple v-if="categories && categories.data" class="mt-2 v-selected-list-scroll"
                :options="categories.data" label="name" placeholder="Chọn phân loại bài viết" :filterable="false"
                v-model="selected_categories" transition="" @search="searchCategories" @open="onOpenCategories"
                @close="onCloseCategories">
                <div slot="no-options">Không tìm thấy!</div>
                <template #list-footer>
                  <li ref="load" v-show="hasNextPageCategories"></li>
                </template>
              </v-select>
            </div>
            <div class="form-group mb-2">
              <label for="" class="robo-18-500 mb-1 required">Tiêu đề</label>
              <input type="text" class="form-control" v-model="form.title" @change="error_title = ''">
              <small class="form-text text-muted text-red">{{ error_title }}</small>
            </div>
            <div class="form-group mb-2">
              <label for="" class="robo-18-500 mb-1 required">Mô tả ngắn</label>
              <textarea class="form-control" v-model="form.desc_short" @change="error_desc = ''" rows="10"
                style="height: 200px;"></textarea>
              <small class="form-text text-muted text-red">{{ error_desc }}</small>
            </div>
            <div class="form-group mb-2">
              <label for="" class="robo-18-500 mb-1">Mô tả đầy đủ</label>
              <EditorInput id="desc_full-newpp" :prop_text="form.desc_full"
                @onChangeContent="(content) => form.desc_full = content" />
              <!-- <summernote ref="summernote" :value="desc_full_default" @ready="readySummernote"></summernote> -->
            </div>
            <div class="row">
              <div class="col-sm-9">
                <div class="form-group mb-2">
                  <label for="" class="robo-18-500 mb-1">Nguồn tin</label>
                  <input type="text" class="form-control" v-model="form.source">
                </div>
              </div>
              <div class="col-sm-3 mb-2">
                <div class="form-group">
                  <label for="" class="robo-18-500 mb-1">Thứ tự hiển thị</label>
                  <input type="number" min="0" class="form-control" v-model="form.order">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group mb-2">
                  <label for="" class="robo-18-500 mb-1">Tag</label>
                  <input type="text" class="form-control" v-model="form.tag">
                </div>
              </div>
              <div class="col-sm-4 mb-2">
                <div class="form-group">
                  <label for="" class="robo-18-500 mb-1">Ngày đăng</label>
                  <input type="date" class="form-control" v-model="form.publish_at">
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label for="" class="robo-18-500 mb-1 d-block">Trạng thái</label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" value="1"
                      v-model="form.status">
                    <label class="form-check-label">Active</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" value="0"
                      v-model="form.status">
                    <label class="form-check-label">InActive</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="robo-18-500 mb-1">Ảnh đại diện</label>
                  <input type="file" class="form-control-file" accept=".jpeg,.jpg,.gif,.png"
                    @change="changeAvatar($event)" ref="inputFile">
                  <div class="mr-2 thread-medias-img border bd-muted mt-2" v-if="form && form.image && is_change"
                    :style="`background-image: url('${getBase64URL(form.image)}');`">
                  </div>
                  <div class="mr-2 thread-medias-img border bd-muted mt-2" v-if="form && form.image && !is_change"
                    :style="`background-image: url('${getImageURL(form.image)}');`">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Đóng</button>
            <button type="button" class="btn bg-pri bd-pri text-white" @click="onSubmit()">{{ !newspaper ? 'Thêm' : 'Cập
                          nhật'}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
import vSelect from 'vue-select'
// import summernote from '../../components/Layouts/summernote.vue'
import EditorInput from '@/components/EditorInput.vue'

export default {
  name: 'ModalNewspaper',
  props: ['newspaper'],
  data () {
    return {
      form: {
        title: '',
        image: '',
        desc_short: '',
        desc_full: '',
        source: '',
        tag: '',
        order: '',
        publish_at: window.moment().format('YYYY-MM-DD'),
        status: 1
      },
      categories: null,
      loading: false,
      selected_categories: [],
      id: '',
      error_title: '',
      error_desc: '',
      is_submit: false,
      search: '',
      is_change: true,
      desc_full_default: null
    }
  },
  components: { vSelect, EditorInput },
  computed: {

    hasNextPageCategories () {
      let self = this
      if (self.categories.total > self.categories.count) {
        return true
      }
      return false
    }
  },
  watch: {
    newspaper: function (_new, old) {
      let self = this
      if (_new) {
        self.form = _new
        self.form.publish_at = window.moment(_new.publish_at).format('YYYY-MM-DD')
        if (!_new.desc_full) {
          self.form.desc_full = ''
        }
        self.desc_full_default = _new.desc_full
        self.is_change = false
        if (_new.categories) {
          self.selected_categories = _new.categories
        }
      } else {
        self.emtyValue()
      }
    }
  },
  mounted () {
    window.$('#modal--newspaper').modal({
      focus: false
    })
    window.$('#modal--newspaper').modal('hide')
    this.getNewCategory()
  },
  methods: {
    async getNewCategory (limit = 50, kw) {
      let self = this
      self.loading = true
      let params = {
        limit: limit
      }
      if (kw) {
        params.search = kw
      }
      await self.$rf.getRequest('AdminRequest').getNewCategory(params).then((rs) => {
        self.categories = rs.data
      }).finally(() => {
        self.loading = true
      })
    },
    searchCategories (kw) {
      this.getNewCategory(50, kw)
    },
    async infiniteScrollCategories ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        var limit = this.categories.count + 20
        await this.$nextTick()
        ul.scrollTop = scrollTop
        this.getNewCategory(limit)
      }
    },
    async onOpenCategories () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observerCategories.observe(this.$refs.load)
      }
    },
    onCloseCategories () {
      this.observerCategories.disconnect()
    },
    emtyValue () {
      let self = this
      self.form = {
        title: '',
        image: '',
        desc_short: '',
        desc_full: '',
        source: '',
        tag: '',
        order: '',
        publish_at: window.moment().format('YYYY-MM-DD'),
        status: 1,
        new_category_ids: []
      }
      self.selected_categories = []
      self.$refs.inputFile.value = null
    },
    async onSubmit () {
      let self = this
      try {
        if (self.checkValidate()) {
          if (self.clinic_item) {
            self.form.clinic_id = self.clinic_item.id
          }
          if (self.selected_categories && self.selected_categories.length) {
            let selected_categories = self.selected_categories

            let new_category_ids = []
            selected_categories.forEach(c => {
              new_category_ids.push(c.id)
            })
            self.form.new_category_ids = new_category_ids
          }
          var formData = new FormData()
          for (const [key, value] of Object.entries(self.form)) {
            if (value) {
              if (key === 'publish_at') {
                var now = new Date()
                var time = new Date(value)
                time.setTime(now.getTime())
                formData.append(key, window.moment(time).format('YYYY-MM-DD HH:mm:ss'))
              } else {
                formData.append(key, value)
              }
            }
          }
          if (self.newspaper) {
            self.$emit('onSubmitUpdate', self.form.id, formData)
          } else {
            self.$emit('onSubmitAdd', formData)
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    getBase64URL (file) {
      return URL.createObjectURL(file)
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    checkValidate () {
      let self = this
      let result = true
      if (!self.form.title) {
        self.error_title = 'Vui lòng nhập tiêu đề bài viết'
        result = false
      }
      if (!self.form.desc_short) {
        self.error_desc = 'Vui lòng nhập mô tả ngắn'
        result = false
      }
      return result
    },
    changeAvatar (event) {
      let self = this
      event.target.files.forEach(item => {
        if (item) {
          if (item.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null) {
            self.form.image = item
            self.is_change = true
          } else {
            self.$toast.open({
              message: 'Không thể chọn tệp ' + item.name + ' do không hợp lệ',
              type: 'warning'
            })
            self.form.image = null
          }
        }
      })
    }
    // readySummernote (editor) {
    //   let self = this
    //   editor.summernote('code', this.contents)
    //   editor.$on('change', contents => {
    //     self.form.desc_full = contents
    //   })
    // }
  }
}
</script>
<style scoped>
:root {
  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}

.thread-medias-img {
  width: 126px;
  height: 126px;
  border-radius: 12px;
  background-size: cover;
  cursor: pointer;
}

input[type="date"],
textarea {
  height: 60px;
  color: #20419B;
  font-weight: 500px;
  font-size: 18px;
}

.ck-editor__editable_inline {
  min-height: 400px;
}
</style>