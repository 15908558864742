<template>
  <div
    ref="modalAddGuardian"
    class="modal fade"
    id="modalAddGuardian"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalAddGuardianLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" style="max-width:1000px" role="document">
      <div class="modal-content" v-loading="loading">
        <div class="d-flex modal-header justify-content-between px-3">
          <div class="modal-title fs-24 fw-700 txt-grey-900 text-center w-100">
            <span class="uppercase text-white">Thêm thông tin người thân</span>
          </div>
          <button
            type="button"
            id="closeModal"
            class="close text-white bg-transparent border-0 mr-3"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">
                Họ và tên
                <span class="text-red-500">*</span>
              </div>
              <input
                class="form--input uppercase h-10 mt-1"
                type="text"
                v-model="form.guardian_name"
              />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">
                Sinh năm
                <span class="text-red-500">*</span>
              </div>
              <input class="form--input h-10 mt-1" type="number" v-model="form.guardian_birth_year" />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">
                Giới tính
                <span class="text-red-500">*</span>
              </div>
              <div class="mt-2 h-10 mt-1">
                <el-radio-group v-model="form.guardian_gender">
                  <el-radio :label="1">Nam</el-radio>
                  <el-radio :label="2">Nữ</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">
                Số điện thoại
                <span class="text-red-500">*</span>
              </div>
              <input class="form--input h-10 mt-1" type="number" v-model="form.guardian_phone" />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">
                Số CMND/CCCD
                <span class="text-red-500">*</span>
              </div>
              <input
                class="form--input h-10 mt-1"
                type="text"
                v-model="form.guardian_identification"
              />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">Ngày cấp</div>
              <input
                class="form--input h-10 mt-1"
                type="date"
                v-model="form.guardian_identification_date"
              />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">Nơi cấp</div>
              <input
                class="form--input h-10 mt-1"
                type="text"
                v-model="form.guardian_identification_place"
              />
            </div>
            <div class="col-md-3"></div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">Địa chỉ</div>
              <input class="form--input h-10 mt-1" type="text" v-model="form.guardian_address" />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">Xã</div>
              <input
                class="form--input h-10 mt-1"
                type="text"
                v-model="form.guardian_address_wards_name"
              />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">Huyện</div>
              <input
                class="form--input h-10 mt-1"
                type="text"
                v-model="form.guardian_address_district_name"
              />
            </div>
            <div class="col-md-3">
              <div class="text-black fs-14 fw-700 required">Tỉnh/Thành phố</div>
              <input
                class="form--input h-10 mt-1"
                type="text"
                v-model="form.guardian_address_province_name"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="text-black fs-14 fw-700 required mb-1">Ghi chú</div>

            <textarea
              class="form-control form-control-bg bg-form txt-pri fs-16 fw-400 m-0"
              style="min-height:130px"
              placeholder="Nhập ghi chú"
              v-model="form.notes"
            ></textarea>
          </div>
        </div>

        <div class="modal-footer">
          <button
            :disabled="isDisabled"
            v-loading="loading"
            @click="onSubmit"
            type="button"
            class="btn bg-pri text-white"
          >Lưu</button>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { mixinAdministrativeForm } from '@/utils/mixinAdministrativeForm'

const DEFAULT_FORM = {
  guardian_name: '',
  guardian_birth_year: '',
  guardian_gender: '',
  guardian_identification: '',
  guardian_identification_date: '',
  guardian_identification_place: '',
  guardian_address: '',
  guardian_address_wards_name: '',
  guardian_address_district_name: '',
  guardian_address_province_name: '',
  guardian_phone: '',
  notes: ''
}

export default {
  name: 'ModalAddGuardian',
  props: ['type', 'personId', 'guardianData', 'mrId'],
  mixins: [mixinAdministrativeForm],

  data () {
    return {
      form: {
        ...DEFAULT_FORM
      },
      defaultForm: { ...DEFAULT_FORM },
      loading: false
    }
  },
  watch: {
    guardianData () {
      this.handleMapUpdateData()
    }
  },

  mounted () {
    this.handleMapUpdateData()
  },
  computed: {
    isDisabled () {
      if (
        !this.form.guardian_name ||
        !this.form.guardian_birth_year ||
        !this.form.guardian_phone ||
        !this.form.guardian_gender ||
        !this.form.guardian_identification
      ) {
        return true
      }
      return false
    }
  },

  methods: {
    handleMapUpdateData () {
      if (this.type === 'update' && this.guardianData) {
        Object.keys(this.form).forEach((item, index) => {
          if (item === 'guardian_identification_date') {
            this.form.guardian_identification_date = window
              .moment(this.guardianData?.guardian_identification_date)
              .format('YYYY-MM-DD')
          } else {
            this.form[item] = this.guardianData[item]
          }
        })
      }
    },
    onSubmit () {
      if (this.isDisabled) {
        this.$toast.open({
          message: 'Vui lòng điền đầy đủ thông tin!',
          type: 'warning'
        })
        return
      }

      if (this.type === 'create') {
        this.createGuardian()
      } else {
        this.postUpdateGuardian()
      }
    },
    async createGuardian () {
      this.loading = true
      const params = {
        ...this.form,
        guardian_name: this.form.guardian_name.toUpperCase(),
        medical_record_id: this.mrId,
        person_id: this.personId
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .postCreateGuardian(params)
        .then(r => {
          this.$emit('onCreatedSuccess', r.data?.data)
          this.form = { ...this.defaultForm }
          this.$toast.open({
            message: 'Thêm thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async postUpdateGuardian () {
      if (!this.guardianData?.id) return

      this.loading = true
      const id = this.guardianData.id
      const params = {
        ...this.form,
        guardian_name: this.form.guardian_name.toUpperCase(),
        medical_record_id: this.mrId,
        person_id: this.personId
      }

      await this.$rf
        .getRequest('DoctorRequest')
        .postUpdateGuardian(id, params)
        .then(r => {
          this.$emit('onUpdateSuccess')
          window.$('button#closeModal').trigger('click')
          this.$toast.open({
            message: 'Chỉnh sửa thành công',
            type: 'success'
          })
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    openModal (show) {
      window.$('#modalAddGuaradian').modal(show ? 'show' : 'hide')
    }
  }
}
</script>
      <style lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reverse-ellipsis {
  /* Your move. */
  text-overflow: clip;
  position: relative;
  background-color: #fff;
}
.reverse-ellipsis:before {
  content: "\02026";
  position: absolute;
  z-index: 1;
  left: -2em;
  background-color: inherit;
  padding-left: 1em;
  margin-left: 0.5em;
}

.reverse-ellipsis span {
  min-width: 100%;
  position: relative;
  display: inline-block;
  float: right;
  overflow: visible;
  background-color: inherit;
  text-indent: 0.5em;
}

.reverse-ellipsis span:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: inherit;
  z-index: 200;
  left: -0.5em;
}
</style>
      